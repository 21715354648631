import { AiFillHeart, AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import MsCardGradient from "@/delivery/components/atoms/CardGradient";
import { MsImage } from "@/delivery/components/atoms/Images";
import MsCardGradientList from "@/delivery/components/molecules/CardGradientList";
import msGlowXTelkomsel from "@delivery/assets/msglow-x-telkomsel.png";

import DiscountListInt, {
  discountVoucher,
} from "@/domain/constant/discount_list";

import voucherHook from "@/domain/use_case/context/voucher/voucher";
import { useContext, useEffect, useState } from "react";
import { setDiscountAmount } from "@/infrastructure/helper/dataLocalStorage";

const MsDiscountListVoucher = () => {
  let navigator = useNavigate();
  const _voucherHook = useContext(voucherHook.Context);

  const handleChooseDiscount = (data: DiscountListInt) => {
    setDiscountAmount(data.discount);
    navigator("/data_diri");
    // _voucherHook?.selectDiscountHook[1](data.discount);
  };

  // useEffect(() => {
  //   if (_voucherHook?.selectDiscountHook[0]) {
  //     navigator("/data_diri");
  //   }
  // }, [_voucherHook?.selectDiscountHook[0]]);

  return (
    <div className="bg-soft-yellow h-[160vh] flex justify-center items-center">
      <div className="overflow-x-auto overflow-y-auto h-full flex flex-col justify-center items-center sm:w-full md:w-1/2 lg:w-1/4">
        <div className="mb-4 flex-none w-3/4 h-14">
          <MsImage url={msGlowXTelkomsel} />
        </div>
        <div className="font-extrabold text-6xl text-red-telkomsel">
          <span>PROMO</span>
        </div>
        <div className="text-blue font-semibold">
          <span className="flex items-center">
            <AiFillHeart className="mr-1" />
            Special Untuk kamu
          </span>
        </div>
        <div className="text-grey-msglow">
          <span>Kami pilihkan sesuai kebutuhan kamu</span>
        </div>
        <div className="text-grey-msglow text-[12px] mt-4">
          <span>Silahkan pilih diskon pilihan anda</span>
        </div>
        <div>
          {discountVoucher.map((item: DiscountListInt, index) => (
            <div key={index}>
              <MsCardGradientList
                onClick={() => handleChooseDiscount(item)}
                bigText={item.discount}>
                <div>
                  {item.product_list.map((item, index) => (
                    <span key={index}>
                      <p className="flex items-center">
                        <AiFillStar className="mr-1" />
                        {item}
                      </p>
                    </span>
                  ))}
                </div>
              </MsCardGradientList>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MsDiscountListVoucher;
