import { ChangeEvent, useContext, useEffect, useState } from "react";
import { BiSolidMap } from "react-icons/bi";
import { TextField, TextareaAutosize } from "@mui/material";

import { AutoComplete } from "@/delivery/components/atoms/Autocomplete";
import MsSellerList from "@/delivery/components/atoms/SellerList";
import MsButton from "@/delivery/components/atoms/Button";

import { cityList } from "@/domain/constant/city";
import { provinceList } from "@/domain/constant/province";
import { sellerList } from "@/domain/constant/seller_list";
import MsTextField, {
  MsTextField2,
} from "@/delivery/components/atoms/TextField";

import voucherHook from "@/domain/use_case/context/voucher/voucher";
import {
  getDiscountAmount,
  getVoucherCode,
} from "@/infrastructure/helper/dataLocalStorage";

const MsSellerListVoucher = () => {
  const _voucherHook = useContext(voucherHook.Context);

  /* -------------------------------- LOCATION -------------------------------- */
  const handleAddProvince = (event: any, value: any) => {
    _voucherHook?.provinceIdHook[1](value.id);
    _voucherHook?.provinceHook[1](value.name);
  };

  const handleAddCity = (event: any, value: any) => {
    _voucherHook?.cityHook[1](value.name);
  };

  /* ------------------------------ END LOCATION ------------------------------ */

  const [disableCity, setDisableCity] = useState(true);

  useEffect(() => {
    _voucherHook?.fetchProvinces();
  }, []);

  useEffect(() => {
    if (_voucherHook?.provinceIdHook[0]) {
      _voucherHook?.fetchCity(_voucherHook?.provinceIdHook[0]);
      setDisableCity(false);
    }
  }, [_voucherHook?.provinceIdHook[0]]);

  return (
    <div className="bg-map-background h-[130vh] flex justify-center item-center">
      <div className="flex flex-col justify-center items-center sm:w-full md:w-1/2 lg:w-1/2">
        <div className="mb-10">
          <div className="mt-4">
            <span className="text-white">Kode Voucher: </span>
            <span className="text-white font-bold">{getVoucherCode()}</span>
          </div>
          <div className="mt-4 w-full">
            <span className="text-white">Discount: </span>
            <span className="text-white font-bold">{getDiscountAmount()}</span>
          </div>
        </div>
        <div>
          <div className="flex-none w-full mb-2 flex justify-center">
            <p className="text-white text-xl font-bold">PILIH LOKASI SELLER</p>
          </div>
          <div className="mt-4">
            <span className="text-white font-bold">Provinsi</span>
            <AutoComplete
              label="Pilih Provinsi"
              items={_voucherHook?.provinces}
              onChange={handleAddProvince}
            />
          </div>
          <div className="mt-4">
            <span className="text-white font-bold">Kota</span>
            <AutoComplete
              disabled={disableCity}
              label="Pilih Kota"
              items={_voucherHook?.cities}
              onChange={handleAddCity}
            />
          </div>
        </div>
        <div>
          <div className="flex-none w-full mb-2 mt-10 flex justify-center">
            <p className="text-white text-xl font-bold">MASUKAN DATA DIRI</p>
          </div>
          <div className="w-full">
            <MsTextField2
              label="Nama"
              placeholder="Nama"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                _voucherHook?.nameHook[1](e.target.value)
              }
            />
            <MsTextField2
              type="number"
              label="Nomor Hp"
              placeholder="Nomor Hp"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                _voucherHook?.phoneHook[1](e.target.value)
              }
            />
            <MsTextField2
              label="Alamat"
              placeholder="Alamat"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                _voucherHook?.addressHook[1](e.target.value)
              }
            />
          </div>
        </div>

        <div className="mt-10 w-full">
          <MsButton
            label="Submit"
            onClick={() => _voucherHook?.submitWhatsAppAutoText()}
          />
        </div>
      </div>
    </div>
  );
};

export default MsSellerListVoucher;
