interface MsImageProps {
  url?: string;
  width?: number;
  height?: number;
}

export function MsImage(props: MsImageProps) {
  const { url, width, height } = props;
  return <img className={`object-cover object-center`} src={url} />;
}
