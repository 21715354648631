import { Autocomplete, Box, FormControl, TextField } from "@mui/material";

interface AutocompleteProps {
  label?: string;
  value?: number | string;
  onChange?:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void)
    | undefined;
  items?: any;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  itemsAggregation?: [] | undefined;
}

export function AutoComplete(props: AutocompleteProps) {
  const { items, onChange, size, disabled, label } = props;
  return (
    <Box
      sx={{
        minWidth: 120,
        width: "100%",
        borderRadius: "12px",
      }}>
      <FormControl size={props.size} fullWidth>
        <Autocomplete
          disableClearable
          fullWidth
          disabled={disabled}
          size={size}
          onChange={onChange}
          getOptionLabel={(option: any) => option.name}
          groupBy={(option: any) => option.categoryName}
          disablePortal
          id="combo-box-demo"
          options={items}
          sx={{
            width: 300,
            backgroundColor: "#FFFFFF",
            borderRadius: "12px",
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder={label} />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
        />
      </FormControl>
    </Box>
  );
}
