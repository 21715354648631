interface CardGradientProps {
  bigText?: string;
}

const MsCardGradient = (props: CardGradientProps) => {
  const { bigText } = props;

  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(to right, #FF0221 , #FFAA56",
        }}
        className="font-extrabold text-5xl text-white bg- mt-9 px-6 py-6 rounded-3xl"
      >
        <span>{bigText}</span>
      </div>
    </>
  );
};

export default MsCardGradient;
