export const VouchersApi = {
  SHOW: "/voucher",
  FETCH: "/vouchers",
  CLAIM: "/claim",
  CREATE: "/create",
  IMPORT: "/import",
};

export const DiscountApi = {
  FETCH: "/discounts",
  CREATE: "/discount/create",
};

export const LocationApi = {
  PROVINCE: "/provinces",
  CITY: "/cities",
};
