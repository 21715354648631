export function setVoucherCode(data: string) {
  localStorage.setItem("voucherCode", data);
}

export function setDiscountAmount(data: string) {
  localStorage.setItem("discountAmount", data);
}

export function getVoucherCode() {
  return localStorage.getItem("voucherCode");
}

export function getDiscountAmount() {
  return localStorage.getItem("discountAmount");
}
