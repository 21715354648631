import MsCardGradient from "@delivery/components/atoms/CardGradient";
import { ReactNode } from "react";

interface MsCardGradientListProps {
  bigText?: string;
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const MsCardGradientList = (props: MsCardGradientListProps) => {
  const { bigText, children, onClick } = props;
  return (
    <div className="flex items-center flex-col">
      <div>
        <button onClick={onClick}>
          <MsCardGradient bigText={bigText} />
        </button>
      </div>

      {children}
    </div>
  );
};

export default MsCardGradientList;
