import { toast } from "react-toastify";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MsButton from "@/delivery/components/atoms/Button";
import { MsImage } from "@/delivery/components/atoms/Images";
import MsTextField from "@/delivery/components/atoms/TextField";

import telkomselPoint from "@delivery/assets/telkomsel-poin.png";
import { useDependency } from "@/domain/use_case/main/dependecy_context";
import MsNotification from "@/delivery/components/atoms/Notification/notification";

import voucherHook from "@/domain/use_case/context/voucher/voucher";

const MsRegistrationVoucher = () => {
  const { code } = useParams();

  const _voucherHook = useContext(voucherHook.Context);

  //hook form
  const [noVoucher, setNoVoucher] = useState<string | undefined>("");

  useEffect(() => {
    setNoVoucher(code);
  }, [code]);

  return (
    <>
      <MsNotification />
      <div className="flex justify-center item-center h-screen bg-corla2 bg-cover bg-center">
        <div className="flex flex-col justify-center items-center sm:w-full md:w-1/2 lg:w-1/4">
          <div className="flex-none w-full h-14">
            <p className="flex justify-center align-middle text-white text-3xl font-bold">
              Voucher Top Up Saldo
            </p>
          </div>
          <br />
          <div className="mb-4">
            <span className="font-semibold text-2xl">VOUCHER</span>
          </div>
          <div className="font-extrabold text-4xl text-bright-yellow">
            <span>DISCOUNT</span>
          </div>
          <div className="font-extrabold text-6xl text-bright-yellow">
            <span>UP TO 25%</span>
          </div>
          <br />
          <div className="bg-white px-5 py-5 rounded-xl sm:w-4/5 flex justify-center items-center flex-col">
            <span className="font-extrabold text-red-telkomsel text-2xl">
              REGISTRATION
            </span>
            <br />
            <div className="w-full">
              <MsTextField
                value={code}
                placeholder="Nomor Voucher"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNoVoucher(e.target.value)
                }
              />
            </div>

            <div className="w-full mt-4">
              <MsButton
                label="Submit"
                loading={_voucherHook?.loadingButton}
                onClick={() =>
                  _voucherHook!.claimVoucher({
                    no_voucher: noVoucher,
                    type: "voucher",
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MsRegistrationVoucher;
