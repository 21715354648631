import voucherHook from "@/domain/use_case/context/voucher/voucher";
import MsRegistrationVoucher from "./registration_pulsa";
import { useEffect } from "react";

const RegistrationVoucher = () => {
  const VoucherProvider = voucherHook.Provider;

  useEffect(() => {
    document.title = "Voucher Pulsa";
  }, []);

  return (
    <VoucherProvider>
      <MsRegistrationVoucher />
    </VoucherProvider>
  );
};

export default RegistrationVoucher;
