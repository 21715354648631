import { toast } from "react-toastify";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import VoucherInterfaceUseCase from "../../interface/voucher";

import { VoucherRepository } from "@/domain/repository/apis/voucher";
import { LocationRepository } from "@/domain/repository/apis/location";
import { WA_NUMBER } from "@/domain/constant/wa_number";
import {
  getDiscountAmount,
  getVoucherCode,
  setVoucherCode,
} from "@/infrastructure/helper/dataLocalStorage";

function voucherHook() {
  const Context = createContext<VoucherInterfaceUseCase | null>(null);

  const Provider = (props: any) => {
    let navigator = useNavigate();
    const voucherObj = new VoucherRepository();
    const locationObj = new LocationRepository();

    const [loadingButton, setLoadingButton] = useState(false);

    const [provinces, setProvinces] = useState<any | undefined>();
    const [cities, setCities] = useState<any | undefined>();

    const selectDiscountHook = useState("");

    const provinceIdHook = useState("");
    const provinceHook = useState("");
    const cityHook = useState("");

    const nameHook = useState("");
    const addressHook = useState("");
    const phoneHook = useState("");

    const fetchVoucher = () => {
      //fetch voucher
    };

    /* ------------------------------ claim voucher ----------------------------- */
    const claimVoucher = (data?: any) => {
      // form validation
      if (data.no_voucher === "") {
        toast.error("silahkan masukan nomor voucher", {});
        return;
      }

      setLoadingButton(true);
      let params = {
        code: data.no_voucher,
      };

      voucherObj
        .showVoucher(params)
        .then((result) => {
          toast.success("success", {});
          setLoadingButton(false);
          setVoucherCode(data.no_voucher);

          if (data.type === "voucher") {
            navigator("/discount_list_pulsa");
          }

          if (data.type === "point") {
            navigator("/discount_list_point");
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {});
          setLoadingButton(false);
        });
    };

    /* ------------------------ submit whatsapp autotext ------------------------ */
    const submitWhatsAppAutoText = () => {
      let openingMessage =
        "saya ingin melakukan pesanan dengan detail sebagai berikut";
      let message = `${openingMessage}
      kode voucher: ${getVoucherCode()},
      pilihan diskon: ${getDiscountAmount()},
      nama: ${nameHook[0].toUpperCase()},
      nomor hp: ${phoneHook[0].toUpperCase()},
      alamat: ${addressHook[0]},
      pilihan diskon: ${selectDiscountHook[0].toUpperCase()},
      pilihan daerah seller:  ${cityHook[0]}, ${provinceHook[0]} `;

      let formatedMessage = encodeURIComponent(message);

      window.open(`https://wa.me/${WA_NUMBER}?text=${formatedMessage}`);
    };

    /* -------------------------------- LOCATION -------------------------------- */
    /* ----------------------------- fetch provinces ---------------------------- */
    const fetchProvinces = () => {
      let params = {};

      locationObj
        .fetchProvinces(params)
        .then((result: any) => {
          setProvinces(result);
        })
        .catch((error) => {
          toast.error("error", {});
        });
    };

    /* ------------------------------- fetch city ------------------------------- */
    const fetchCity = (data: any) => {
      let params = {
        province_id: data,
      };

      locationObj
        .fetchCity(params)
        .then((result: any) => {
          setCities(result);
        })
        .catch((error) => {
          toast.error("error", {});
        });
    };

    const voucherGroupState: VoucherInterfaceUseCase = {
      provinceIdHook,
      selectDiscountHook,
      submitWhatsAppAutoText,
      nameHook,
      addressHook,
      phoneHook,
      provinceHook,
      cityHook,
      provinces,
      cities,
      loadingButton,
      fetchProvinces,
      fetchCity,
      fetchVoucher,
      claimVoucher,
    };

    // return voucherGroupState;
    return (
      <Context.Provider value={voucherGroupState}>
        {props.children}
      </Context.Provider>
    );
  };

  return {
    Context,
    Provider,
  };
}

export default voucherHook();
