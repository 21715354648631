import { AxiosApi } from "@repository/api_client/axios_client";
import { LocationApi } from "@/domain/constant/api_list";
import LocationInterface from "@repository/interface/location";

export class LocationRepository implements LocationInterface {
  api = new AxiosApi();

  async fetchProvinces(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(LocationApi.PROVINCE, data)
          .then((result) => {
            let data = result.data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async fetchCity(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(`${LocationApi.CITY}?province_id=${data.province_id}`, data)
          .then((result) => {
            let data = result.data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }
}
