import VoucherInterface from "@repository/interface/voucher";
import { AxiosApi } from "@repository/api_client/axios_client";
import { VouchersApi } from "@/domain/constant/api_list";

export class VoucherRepository implements VoucherInterface {
  api = new AxiosApi();
  async fetchVoucher(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(VouchersApi.FETCH, data)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async showVoucher(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(`${VouchersApi.SHOW}/${data.code}`, data)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async claimVoucher(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(VouchersApi.CLAIM, data)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async createVoucher(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(VouchersApi.CREATE, data)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }

  async importVoucherExcel(data: any) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(VouchersApi.IMPORT, data)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch {
      return null;
    }
  }
}
