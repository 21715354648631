import { TextField } from "@mui/material";

interface MsTextFieldProps {
  type?: "text" | "number";
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  size?: "small" | "medium";
  value?: string | number;
}

const MsTextField = (props: MsTextFieldProps) => {
  const { type, label, placeholder, onChange, onKeyDown, required, value } =
    props;

  return (
    <div>
      <input
        value={value}
        required={required}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className="px-4 py-2 rounded-xl block border border-grey-msglow focus:outline-red-telkomsel w-full"
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};

const MsTextField2 = (props: MsTextFieldProps) => {
  const { type, label, placeholder, onChange, onKeyDown, required, size } =
    props;

  return (
    <div className="w-full my-2">
      <span className="text-white font-bold">{label}</span>
      <TextField
        required={required}
        placeholder={placeholder}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "5px",
        }}
        type={type}
        fullWidth
        variant="outlined"
        size={size}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export { MsTextField2 };
export default MsTextField;
