import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface MsNotificationProps {}

const MsNotification = (props: MsNotificationProps) => {
  const {} = props;
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        theme="light"
        closeOnClick
        hideProgressBar={false}
      />
    </>
  );
};

export default MsNotification;
