import voucherHook from "@/domain/use_case/context/voucher/voucher";
import MsDiscountListVoucher from "./discount_list_pulsa";
import { useEffect } from "react";

const DiscountListVoucher = () => {
  const VoucherProvider = voucherHook.Provider;

  useEffect(() => {
    document.title = "Discount List Pulsa";
  }, []);

  return (
    <VoucherProvider>
      <MsDiscountListVoucher />
    </VoucherProvider>
  );
};

export default DiscountListVoucher;
