import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import MsRegistrationPoint from "@/delivery/ui/registration_point";
import MsDiscountListPoint from "@/delivery/ui/discount_list_point/discount_list_point";

import MsSellerListVoucher from "@/delivery/ui/personal_information";
import MsRegistrationVoucher from "@/delivery/ui/registration_pulsa";
import MsDiscountListVoucher from "@/delivery/ui/discount_list_pulsa";

function App() {
  return (
    <Routes>
      <Route path="/point/:code" element={<MsRegistrationPoint />} />
      <Route path="/discount_list_point" element={<MsDiscountListPoint />} />

      <Route path="/pulsa/:code" element={<MsRegistrationVoucher />} />
      <Route path="/discount_list_pulsa" element={<MsDiscountListVoucher />} />
      <Route path="/data_diri" element={<MsSellerListVoucher />} />
    </Routes>
  );
}

export default App;
