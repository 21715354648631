import AxiosClient from "@/infrastructure/consumeApi/axios/axios";
import { ApiInterface } from "@/domain/repository/interface/api_client";

export class AxiosApi implements ApiInterface {
  async post(url: string, data: any, config?: any, activeUrl?: string) {
    return await AxiosClient({
      activeUrl: activeUrl,
      config: config,
    }).post(url, data);
  }

  async get(url: string, data: any, activeUrl?: string) {
    return await AxiosClient({ activeUrl: activeUrl }).get(url, data);
  }

  async put(url: string, data: any) {
    return await AxiosClient().put(url, data);
  }

  async delete(url: string, data: any) {
    return await AxiosClient().delete(url, { data });
  }
}
