export default interface DiscountListInt {
  discount: string;
  product_list: string[];
}

const discountVoucher: DiscountListInt[] = [
  {
    discount: "70% Disc",
    product_list: [
      "Red Jelly",
      "Radiance Gold",
      "Nail Polish",
      "Eyeliner",
      "Eyebrow",
      "Eyelash",
      "Eyeshadow Pallet",
      "Hair Growth Nutrition",
      "Hair Serum",
    ],
  },
  {
    discount: "50% Disc",
    product_list: [
      "Clay Mask",
      "Underarm",
      "Sexy Glam",
      "Balm Juice",
      "Loose Powder",
      "Lip Serum",
      "Mskids Baby Cream",
      "Mskids Shampoo",
      "Mskids Bubble Wash",
    ],
  },
  {
    discount: "35% Disc",
    product_list: ["Moisturizer Juice", "Cushion", "Sheetmask"],
  },
];

const discountPoint: DiscountListInt[] = [
  {
    discount: "30% Disc",
    product_list: [
      "Radiance Gold",
      "Nail Polish",
      "Eyeliner",
      "Eyebrow",
      "Eyelash",
      "Eyeshadow Pallet",
      "Hair Growth Nutrition",
      "Hair Serum",
      "Clay Mask",
      "Underarm",
      "Sheetmask",
      "Balm Juice",
    ],
  },
  {
    discount: "20% Disc",
    product_list: [
      "Sexy Glam",
      "Loose Powder",
      "Lip Serum",
      "Ms Kids Baby Cream",
      "Ms Kids Shampoo",
      "Ms Kids Bubble Wash",
      "Red Jelly",
    ],
  },
  {
    discount: "10% Disc",
    product_list: [" Moisturizer Juice", "Cushion", "JJ Glow"],
  },
];

export { discountVoucher, discountPoint };
