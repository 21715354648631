import voucherHook from "@/domain/use_case/context/voucher/voucher";
import PersonalInformation from "./personal_information";
import { useEffect } from "react";

const SellerListVoucher = () => {
  const VoucherProvider = voucherHook.Provider;

  useEffect(() => {
    document.title = "Personal Information";
  }, []);

  return (
    <VoucherProvider>
      <PersonalInformation />
    </VoucherProvider>
  );
};

export default SellerListVoucher;
