interface MsButtonProps {
  label: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickHref?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  disabled?: boolean;
  href?: string;
}

const MsButton = (props: MsButtonProps) => {
  const {
    label,
    loading,
    onClick,
    disabled = false,
    href,
    onClickHref,
  } = props;
  return (
    <>
      {href ? (
        <a
          href={href}
          type="button"
          onClick={onClickHref}
          style={{
            backgroundImage: `${"linear-gradient(to right, #FF0221 , #FFAA56)"}`,
          }}
          className="px-6 py-2 bg-brand-gradient text-white rounded-full font-bold w-full">
          {loading ? (
            <div className="flex justify-center">
              <svg
                className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white `}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          ) : (
            <span className="text-center block">{label}</span>
          )}
        </a>
      ) : (
        <button
          disabled={disabled}
          onClick={onClick}
          style={{
            backgroundImage: `${"linear-gradient(to right, #FF0221 , #FFAA56)"}`,
          }}
          className="px-6 py-2 bg-brand-gradient text-white rounded-full font-bold w-full">
          {loading ? (
            <div className="flex justify-center">
              <svg
                className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white `}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          ) : (
            label
          )}
        </button>
      )}
    </>
  );
};

export default MsButton;
