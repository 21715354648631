import { API_BASE_URL } from "@/domain/constant/setting_env";
import axios from "axios";

export interface ConfigParams {
  responseType?: string;
  contentType?: string;
  authorization?: string;
}

type AxiosParams = {
  activeUrl?: string | null;
  config?: ConfigParams;
  otp?: string;
};

function AxiosClient(props?: AxiosParams) {
  const axiosClient = axios.create({
    baseURL: `${API_BASE_URL}/`,
    headers: {
      Authorization: ``,
      "Content-Type": "application/json",
    },
    timeout: 0,
  });

  axiosClient.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      let message = "this api required authorization token";
      console.log(error);

      // if (error.response.status === 401) {
      // }
      return Promise.reject(error);
    }
  );

  return axiosClient;
}

export default AxiosClient;
